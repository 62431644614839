import { render, staticRenderFns } from "./ChoiceInventory.vue?vue&type=template&id=fce7dc98&scoped=true&"
import script from "./ChoiceInventory.vue?vue&type=script&lang=js&"
export * from "./ChoiceInventory.vue?vue&type=script&lang=js&"
import style0 from "./ChoiceInventory.vue?vue&type=style&index=0&id=fce7dc98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fce7dc98",
  null
  
)

export default component.exports