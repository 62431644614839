<template>
  <!-- 选择库存 -->
  <el-dialog width="82%" title="请选择" :visible.sync="dialogTableVisible" class="choiceInventory" :close-on-click-modal="false">
    <el-table
      ref="multipleTable"
      :data="listData"
      border
      style="width: 100%"
      @select="rowClick"
      @select-all="selectAll"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column type="expand">
        <template slot-scope="props">
          <table class="tableBox">
            <tr>
              <td />
              <td>子仓库编号</td>
              <td>货位号</td>
              <td>货位描述</td>
              <td>钢卷号</td>
              <td>件重(吨)</td>
              <td>入库数量(吨)</td>
            </tr>
            <tr v-for="(item, index) in props.row.wmsInventorydetailRespList" :key="index">
              <td>
                <el-checkbox
                  v-model="item.isChoice"
                  @change="checkList($event, props.row.wmsInventorydetailRespList, props.row)"
                />
              </td>
              <td>{{ item.detailWarehouseNo }}</td>
              <td>{{ item.detailLocationNo }}</td>
              <td>{{ item.detailLocationDesc }}</td>
              <td>{{ item.detailCoilNo }}</td>
              <td>{{ item.detailWeights }}</td>
              <td>{{ item.detailInvWeight }}</td>
            </tr>
          </table>
        </template>
      </el-table-column>
      <el-table-column v-for="(item, index) in itemData" :key="index" :label="item.label" :prop="item.prop" />
    </el-table>
    <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    <div slot="footer" class="dialog-footer buttonBox">
      <el-button class="widen-button" size="small" type="primary" @click="confirmData">
        确定
      </el-button>
      <el-button class="widen-button" size="small" plain @click="dialogTableVisible = false">
        取消
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import Pagination from '@/components/Pagination'
import { inventoryPpageByOutCheckLocationNo, pageCheckLocationDetailInfo } from '@/api/warehousingSystem'
export default {
  components: { Pagination },
  props: {
    params: {
      type: Object,
      default: () => { return {} }
    },
    whetherRemove: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      cmpNameArr: [],
      itemData: [
        // { label: '库存编号', prop: 'inventoryNo', width: 180 },
        // { label: '仓库名称', prop: 'warehouseName', width: 180 },
        // { label: '货主名称', prop: 'custName', width: 180 },
        { label: '品名', prop: 'goodsName', width: 120 },
        { label: '材质', prop: 'materials', width: 120 },
        { label: '规格', prop: 'specs', width: 120 },
        { label: '产地 ', prop: 'productions', width: 120 },
        // { label: '下游客户民称', prop: 'downCustName', width: 100 },
        // { label: '库存数量', prop: 'invWeight', width: 100 },
        // { label: '可用量', prop: 'avlbAmount', width: 100 },
        { label: '可用数量(吨)', prop: 'avlbWeight', width: 100 }
      ],
      dialogTableVisible: false,
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  watch: {
    dialogTableVisible: {
      handler(newVal) {
        if (newVal && this.whetherRemove) {
          this.formInline = { ...this.formInline, ...this.params }
          this.getdata()
        }
      }
    }
  },
  methods: {
    confirmData() {
      const arr = []
      this.listData.forEach(item => {
        item.wmsInventorydetailRespList.forEach(val => {
          if (val.isChoice) {
            arr.push({ ...val, downCustName: item.downCustName })
          }
        })
      })
      if (arr.length === 0) {
        this.$message.error('请选择库存')
        return
      }
      this.dialogTableVisible = false
      this.$emit('getInventory', arr)
    },
    // 选择里面孩子的时候
    checkList(e, arr, row) {
      const flag = arr.every(item => {
        return item.isChoice
      })
      this.$refs.multipleTable.toggleRowSelection(row, flag)
      arr.splice()// 此代码不能删除  用于勾选的复选框不改变样式
      this.$forceUpdate()
    },
    // 点击到某一行的时候获取到被点击的
    rowClick(value, row) {
      const arr = []
      value.forEach(item => {
        arr.push(item.id)
      })
      // 如果有点击了上面的就全选   没有的就让他原来的
      this.listData.forEach(item => {
        // 这是选中父元素了
        if (arr.includes(item.id)) {
          item.wmsInventorydetailRespList.forEach(val => {
            val.isChoice = true
          })
          return
          // 这是父元素没选中的
        }
        item.wmsInventorydetailRespList.forEach(val => {
          // 如果是勾选当行的就全部为没选中
          if (item.id === row.id) {
            val.isChoice = false
            return
          }
          // 如果不是就默认原来的
          val.isChoice = !!val.isChoice
        })
      })
    },
    // 点击全选全不选的时候
    selectAll(value) {
      // 如果有点击了上面的就全选   没有的就让他原来的
      this.listData.forEach(item => {
        if (value.length > 0) {
          item.wmsInventorydetailRespList.forEach(val => {
            val.isChoice = true
          })
        } else {
          item.wmsInventorydetailRespList.forEach(val => {
            val.isChoice = false
          })
        }
      })
    },
    // 获取列表数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      // 这是商城的查询
      if (this.params.inventoryId) {
        pageCheckLocationDetailInfo(this.params.inventoryId, res => {
          this.listData = [...res.data.pageData]
          this.listData.forEach(item => {
            item.wmsInventorydetailRespList.forEach(val => {
              val.isChoice = false
            })
          })
          this.total = res.data.total
        })
        return
      }
      // 这是代采的查询
      inventoryPpageByOutCheckLocationNo(this.formInline, res => {
        this.listData = [...res.data.pageData]
        // 当初始化数据的时候就全部不要选中
        this.listData.forEach(item => {
          item.wmsInventorydetailRespList.forEach(val => {
            val.isChoice = false
          })
        })
        this.total = res.data.total
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.choiceInventory {
  .buttonBox {
    text-align: center;
    margin-top: 12px;
  }

  .tableBox {
    margin-left: 16px;
    text-align: center;
    padding: 0 16px;
    width: calc(100% - 32px);
    border-collapse: collapse;

    td {
      width: 150px;
      height: 40px;
      border: 1px solid #ededed;
    }
  }

  .childrenTable {
    margin-left: 100px;
  }

  .buttonCombination {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
